export const LOGIN = 'LOGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = "LOGOUT"
export const USERDATA = 'USERDATA';
export const DASHBOARDDATA = 'DASHBOARDDATA';
export const USERDATA_FAILURE = 'USERDATA_FAILURE';
export const DELETEUSERDATA = 'DELETEUSERDATA';
export const DELETEUSERDATA_FAILURE = 'DELETEUSERDATA_FAILURE';
export const ADDNEWUSER = 'ADDNEWUSER';
export const ADDNEWUSER_FAILURE = 'ADDNEWUSER_FAILURE';
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"; 
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE"

export const ALLCATEGORYDATA = 'ALLCATEGORYDATA';
export const CATEGORYDATA = 'CATEGORYDATA';
export const CATEGORYDATA_FAILURE = 'CATEGORYDATA_FAILURE';
export const ADDNEWCATEGORY = 'ADDNEWCATEGORY';
export const ADDNEWCATEGORY_FAILURE = 'ADDNEWCATEGORY_FAILURE';
export const UPDATECATEGORYDATA = 'UPDATECATEGORYDATA';
export const UPDATECATEGORYDATA_FAILURE = 'UPDATECATEGORYDATA_FAILURE';
export const UPDATECATEGORYSTATUSDATA = 'UPDATECATEGORYSTATUSDATA';
export const DELETECATEGORYDATA = 'DELETECATEGORYDATA';
export const DELETECATEGORYDATA_FAILURE = 'DELETECATEGORYDATA_FAILURE';



export const ALLPAYMENTDATA = 'ALLPAYMENTDATA';
export const ALLPAYMENTDATA_FAILURE = 'ALLPAYMENTDATA_FAILURE';



export const ALLPLANDATA = 'ALLPLANDATA';
export const ALLPLANDATA_FAILURE = 'ALLPLANDATA_FAILURE';
export const PLANDATA = 'PLANDATA';
export const PLANDATA_FAILURE = 'PLANDATA_FAILURE';
export const ADDNEWPLAN = 'ADDNEWPLAN';
export const ADDNEWPLAN_FAILURE = 'ADDNEWPLAN_FAILURE';
export const UPDATEPLANDATA = 'UPDATEPLANDATA';
export const UPDATEPLANDATA_FAILURE = 'UPDATEPLANDATA_FAILURE';
 
export const DELETEPLANDATA = 'DELETEPLANDATA';
export const DELETEPLANDATA_FAILURE = 'DELETEPLANDATA_FAILURE';




export const ALL_CARDATA = 'ALL_CARDATA';
export const CARDATA = 'CARDATA';
export const CARDATA_FAILURE = 'CARDATA_FAILURE';
export const ADD_NEWCAR = 'ADD_NEWCAR';
export const ADD_NEWCAR_FAILURE = 'ADD_NEWCAR_FAILURE';
export const UPDATE_CARDATA = 'UPDATE_CARDATA';
export const UPDATE_CARDATA_FAILURE = 'UPDATE_CARDATA_FAILURE';
export const UPDATE_CARSTATUSDATA = 'UPDATE_CARSTATUSDATA';
export const DELETE_CARDATA = 'DELETE_CARDATA';
export const DELETE_CARDATA_FAILURE = 'DELETE_CARDATA_FAILURE';

export const ALL_VEHICLELOGDATA = 'ALL_VEHICLELOGDATA';
export const VEHICLELOGDATA = 'VEHICLELOGDATA';
export const VEHICLELOGDATA_FAILURE = 'VEHICLELOGDATA_FAILURE';
export const ADDNEWVEHICLELOG = 'ADD_NEWVEHICLELOG';
export const ADD_NEWVEHICLELOG_FAILURE = 'ADD_NEWVEHICLELOG_FAILURE';
export const UPDATE_VEHICLELOGDATA = 'UPDATE_VEHICLELOGDATA';
export const UPDATE_VEHICLELOGDATA_FAILURE = 'UPDATE_VEHICLELOGDATA_FAILURE';
export const UPDATE_VEHICLELOGSTATUSDATA = 'UPDATE_VEHICLELOGSTATUSDATA';
export const DELETE_VEHICLELOGDATA = 'DELETE_VEHICLELOGDATA';
export const DELETE_VEHICLELOGDATA_FAILURE = 'DELETE_VEHICLELOGDATA_FAILURE';




export const ALLBLOGDATA = 'ALLBLOGDATA';
export const ALLBLOGDATA_FAILURE = 'ALLBLOGDATA_FAILURE';
export const UPDATEBLOGDATA = 'UPDATEBLOGDATA';
export const UPDATEBLOGDATA_FAILURE = 'UPDATEBLOGDATA_FAILURE';
export const DELETEBLOGDATA = 'DELETEBLOGDATA';
export const DELETEBLOGDATA_FAILURE = 'DELETEBLOGDATA_FAILURE';
 
export const ADDNEWBLOG = 'ADDNEWBLOG';
export const ADDNEWBLOG_FAILURE = 'ADDNEWBLOG_FAILURE';


